/* Fonts */
@font-face {
  font-family: 'SVN-SAF';
  src: url("../fonts/SVN-SAF.eot");
  src: url("../fonts/SVN-SAF.eot?#iefix") format("embedded-opentype"), url("../fonts/SVN-SAF.woff2") format("woff2"), url("../fonts/SVN-SAF.woff") format("woff"), url("../fonts/SVN-SAF.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1330px;
  }
}

ol,
ul {
  padding: 0;
  margin: 0;
}

a {
  color: #d05433;
  font-weight: 500;
  transition: all 0.5s ease;
  text-decoration: none;
}

a:hover {
  color: #982f34;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'SVN-SAF', sans-serif;
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
}

img {
  max-width: 100%;
}

button {
  transition: all 0.5s ease;
}

.modal-backdrop {

}

.modal-backdrop.show {
  opacity: 0.7;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
.text-center {
  text-align: center;
}

.number {
  font-family: 'SVN-SAF', sans-serif;
  padding: 0 5px;
}
.symbol {
  color: rgba(255, 255, 255, 0.8);
  font-size: 70%;
}

.text-heading {
  font-family: 'SVN-SAF', sans-serif;
}

.text-f-body {
  font-family: 'iCiel Stabile', sans-serif;
}

.text-yellow {
  color: #ffe843 !important;
}

::placeholder {
  opacity: 1;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #fff;
}
:-moz-placeholder { /* Firefox 18- */
  color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-floating>label {
  font-size: 16px;
}

.offcanvas-start {
  max-width: 270px;
  background: #76281a;
}

.range-slider {
  min-width: 100%;
  border: 1px solid #555;
  border-radius: 8px;
  height: 8px;
  outline: none;
  transition: background 450ms ease-in 0s;
  appearance: none;
  margin: 20px 0;
  background: transparent;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 50%;
  background: #D75212;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 50%;
  background: #D75212;
  cursor: pointer;
}

.connectModal .modal-content {
  background: #692319 url('../../assets/images/modal-bg.jpg') no-repeat center center !important;
  background-size: cover;
  border-radius: 60px !important;
  border: 2px solid #fff !important;
}

@media (min-width: 576px) {
  .connectModal .modal-dialog {
    max-width: 538px;
  }
}

.connectModal .modal-content::before {
   content: '';
   position: absolute;
   top: 0;
   left: 50%;
   z-index: 10;
   transform: translate(-50%, -55%);
   display: block;
   width: 380px;
   height: 170px;
   background: url('../../assets/images/footer-logo.png') no-repeat center center;
   background-size: 100%;
}

.connectModal .modal-content::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, 50%);
  display: block;
  width: 100px;
  min-height: 80px;
  background: url('../../assets/images/logo.png') no-repeat center center;
  background-size: 100%;
}

.connectModal .btn-close {
  background: url('../../assets/images/close.svg') no-repeat top center !important;
  box-shadow: none;
  opacity: 1;
}

.btn-close {
  background: transparent url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
  width: 0.5rem;
  height: 0.5rem;
  opacity: 0.9;
  top: -30px !important;
  right: 10px !important;
}

.tooltip-inner {
  background: #fff !important;
  color: #000;
}
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #fff !important;
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #fff !important;
}

select option {
  color: #000;
}

.text-small {
  font-size: 14px;
}

.text-left {
  text-align: left;
}

.flex-column {
  flex-direction: column;
}

.image-banned {
  filter: blur(10px);
  -webkit-filter: blur(10px);
}

.offcanvas-header .btn-close {
  filter: none;
}

.mb-42 {
  margin-bottom: 42px !important;
}
