.Toastify__toast {
  font-family: 'SVN-SAF',sans-serif;
  background-color: #ff5f04;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}
.Toastify__toast .Toastify__toast-body,
.Toastify__toast .Toastify__close-button {
  color: #fff;
}
.Toastify__toast .toastify-header {
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Toastify__toast .toastify-header .title-wrapper {
  display: flex;
  align-items: center;
}
.Toastify__toast .toastify-header .title-wrapper .toastify-icon {
  display: flex;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.Toastify__toast .toastify-header .toastify-title {
  font-size: 1rem;
  font-weight: 600;
}
.Toastify__toast .toastify-body {
  padding-left: 1rem;
  font-size: 0.85rem;
  line-height: 1.5;
}
.Toastify__toast .toastify-link {
  display: block;
  font-family: 'Rubik', sans-serif;
  color: #fff;
  padding-left: 2.5rem;
  font-size: 0.85rem;
  line-height: 1.5;
  text-decoration: underline;
}
.Toastify__toast .toastify-link:hover {
  color: #fff;
  text-decoration: underline;
}
.Toastify__toast .Toastify__close-button {
  opacity: 1;
  margin-top: 1px;
  margin-left: 0.5rem;
}
.Toastify__toast .Toastify__close-button svg {
  height: 0.85rem;
  width: 0.85rem;
  fill: #fff;
}
.Toastify__toast .Toastify__close-button:hover svg {
  fill: #fff;
}
.Toastify__toast .Toastify__close-button:focus {
  outline: 0;
}
.Toastify__toast.Toastify__toast--default .toastify-title,
.Toastify__toast.Toastify__toast--success .toastify-title {
  color: #63c608;
}
.Toastify__toast.Toastify__toast--default .toastify-icon,
.Toastify__toast.Toastify__toast--success .toastify-icon {
  background-color: #63c608;
}
.Toastify__toast.Toastify__toast--error .toastify-title {
  color: #982f34;
}
.Toastify__toast.Toastify__toast--error .toastify-icon {
  background-color: #982f34;
}
.Toastify__toast.Toastify__toast--warning .toastify-title {
  color: #d75212;
}
.Toastify__toast.Toastify__toast--warning .toastify-icon {
  background-color: #fff;
}
